import React from 'react';
import { Thumbnail } from 'react-bootstrap';
class SceneThumbnail extends React.PureComponent {
    state = {
        zoom: false,
        retryCount: 0,
    };
    onLoad = (e) => {
        this.image = e.target;
        this.setImageDefaultSize();
    };
    onError = (e) => {
        if (this.state.retryCount < 3) {
            this.retryOnErrorTimeout = setTimeout(
                () => this.setState({ retryCount: this.state.retryCount + 1 }),
                500
            );
        }
    };
    componentWillUnmount = () => {
        if (this.retryOnErrorTimeout) {
            clearTimeout(this.retryOnErrorTimeout);
        }
    };
    setImageMaxSize = () => {
        this.image.style.maxHeight = '100%';
        this.setState({ zoom: true });
    };
    setImageDefaultSize = () => {
        this.image.style.maxHeight = '50%';
        this.setState({ zoom: false });
    };
    getSrc = () =>
        this.props.retry
            ? this.props.src + '?' + this.state.retryCount
            : this.props.src;
    render = () => {
        const { children } = this.props;
        const { zoom } = this.state;
        return (
            <Thumbnail
                onLoad={this.onLoad}
                src={this.getSrc()}
                style={{
                    backgroundColor: '#BBBBBB',
                }}
                onError={this.onError}
            >
                {!zoom && (
                    <div
                        style={{
                            padding: 10,
                            backgroundColor: 'white',
                            wordWrap: 'break-word',
                        }}
                    >
                        {children}
                    </div>
                )}
            </Thumbnail>
        );
    };
}

export { SceneThumbnail };

