import React from 'react';
import '../../css/App.css';
import { Panel, Thumbnail, Row, Button } from 'react-bootstrap';
import Clipboard from 'clipboard';
import logo from '../../assets/icon128.png';
import githublogo from '../../assets/GitHub-Mark-32px.png';
import { SceneItem } from './SceneItem';
import { sceneStore } from '../SceneStore';
import { UploadSceneWidget } from './UploadSceneWidget';
import LoadingWidget from './loadingWidget';

const BottomFixedPanel = ({ showUploadScene, onToggleUploadSceneView }) => (
    <Panel
        style={{
            position: 'fixed',
            textAlign: 'center',
            left: 0,
            bottom: 0,
            zIndex: 1,
            marginBottom: 0,
            width: '100%',
            marginTop: -15,
        }}
    >
        <Button
            bsStyle="default"
            onClick={onToggleUploadSceneView}
            style={{ marginRight: 32 }}
        >
            {!showUploadScene ? 'Upload scene' : 'Hide upload form'}
        </Button>
    </Panel>
);

class BtcDonate extends React.Component {
    state = {
        clicked: false,
    };
    onClick = () => this.setState({ clicked: !this.state.clicked });
    render() {
        if (this.state.clicked) {
            return <p>bc1qc2sxhz9gtttuthvnvh3ra8z49f9qf9fyugzhhg</p>;
        }
        return (
            <p>
                <Button bsSize="xsmall" onClick={this.onClick}>
                    Donate BTC
                </Button>
                <br />
                <small className="text-muted">
                    for further development of AudioVisualizer
                </small>
            </p>
        );
    }
}

const Header = ({ showUploadScene }) => (
    <div>
        {!showUploadScene ? (
            <Thumbnail src={logo} style={{ textAlign: 'center' }}>
                <div style={{ marginTop: -50, position: 'relative' }}>
                    <h3>
                        <a href="https://chrome.google.com/webstore/detail/audiovisualizer/bojhikphaecldnbdekplmadjkflgbkfh">
                            Audio Visualizer
                        </a>
                    </h3>
                    <em>scene sharing</em>
                    <a href="https://github.com/afreakk/ChromeAudioVisualizerExtension">
                        <img
                            alt="scene"
                            src={githublogo}
                            style={{
                                position: 'absolute',
                                right: 5,
                                bottom: 5,
                                height: '50%',
                            }}
                        />
                    </a>
                    <div
                        style={{
                            position: 'absolute',
                            left: 5,
                            bottom: 6,
                            height: '50%',
                        }}
                    >
                        <BtcDonate />
                    </div>
                </div>
            </Thumbnail>
        ) : (
            <UploadSceneWidget />
        )}
    </div>
);
const SceneTiles = ({ scenes, sceneFilter }) => (
    <Row>
        {scenes.map((scene) => (
            <SceneItem scene={scene} key={scene.id} sceneFilter={sceneFilter} />
        ))}
    </Row>
);

const getScrollPxFromTheEdge = () => {
    const max =
        Math.max(
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        ) - window.innerHeight;
    return max - window.scrollY;
};

class App extends React.Component {
    state = {
        scenes: [],
        showUploadScene: false,
        sceneFilter: '',
        loading: true,
    };
    componentDidMount() {
        this.sceneChangeSubscriberId = sceneStore.subscribeToChanges((scenes) =>
            this.setState({ scenes })
        );
        sceneStore.fetchPage().then(() => this.setState({ loading: false }));
        window.addEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        if (this.state.loading) {
            if (!this.timeoutSet) {
                this.timeoutSet = true;
                setTimeout(() => {
                    this.handleScroll();
                    this.timeoutSet = false;
                }, 200);
            }
        } else if (getScrollPxFromTheEdge() < 100) {
            if (this.state.sceneFilter) {
                this.fetchSearch();
            } else if (!sceneStore.isExhausted()) {
                this.fetchPage();
            }
        }
    };
    fetchPage = () => {
        this.setState({ loading: true });
        sceneStore.fetchPage().then(this.stopLoading);
    };
    fetchSearch = (value = this.state.sceneFilter) => {
        if (value.length && !sceneStore.isExhausted(value)) {
            this.setState({ loading: true });
            sceneStore.fetchSeach(value).then(this.stopLoading);
        }
    };
    stopLoading = () => this.setState({ loading: false });
    componentWillUnmount() {
        sceneStore.unsubscribeToChanges(this.sceneChangeSubscriberId);
        window.removeEventListener('scroll', this.handleScroll);
    }
    scenesDidChange() {
        new Clipboard('.btn');
        this.handleScroll();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.scenes.length !== this.state.scenes.length)
            this.scenesDidChange();
        if (
            prevState.showUploadScene !== this.state.showUploadScene &&
            this.state.showUploadScene
        )
            window.scrollTo(0, 0);
    }
    onChangeSearchValue = (e) => {
        // all names are lowercase in db
        const value = e.target.value.toLowerCase();
        this.setState({ sceneFilter: value });
        this.fetchSearch(value);
    };
    onToggleUploadSceneView = () =>
        this.setState({ showUploadScene: !this.state.showUploadScene });
    getScenes = () =>
        this.state.sceneFilter
            ? this.state.scenes.map((s) =>
                  s.title.startsWith(this.state.sceneFilter)
                      ? s
                      : { ...s, hidden: true }
              )
            : this.state.scenes;
    render = () => (
        <div className="container" style={{ marginBottom: 100 }}>
            <Header showUploadScene={this.state.showUploadScene} />
            <Panel style={{ textAlign: 'center', padding: '15px 0px' }}>
                Search:&nbsp;
                <input
                    onChange={this.onChangeSearchValue}
                    type="text"
                    id="SceneSearch"
                    value={this.state.sceneFilter}
                    style={{
                        width: 200,
                        display: 'inline',
                        verticalAlign: 'middle',
                    }}
                />
            </Panel>
            <SceneTiles
                scenes={this.getScenes()}
                sceneFilter={this.state.sceneFilter}
            />
            {this.state.loading && <LoadingWidget height={200} />}
            <BottomFixedPanel
                showUploadScene={this.state.showUploadScene}
                onToggleUploadSceneView={this.onToggleUploadSceneView}
            />
        </div>
    );
}

export default App;
