const API_GET_ENDPOINT = process.env.REACT_APP_GET_ENDPOINT;
const API_POST_ENDPOINT = process.env.REACT_APP_POST_ENDPOINT;
const API_SEARCH_ENDPOINT = process.env.REACT_APP_SEARCH_ENDPOINT;

const argUrl = (url, params) => {
    url = new URL(url);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    return url;
};
const getImgUrl = (id) => `/imgs/${id}`;

class SceneStore {
    constructor() {
        this.changeSubscribers = {};
        this.scenes = [];
        this.queryExhausted = {};
        this.LastEvaluatedKey = {};
    }
    setScenes(scenes) {
        this.scenes = scenes;
        this._broadcastNewScene();
        return true;
    }
    handleDirtyScenes = (scenes) =>
        this.sortScenes(
            this.scenes.concat(
                this._expandScenes(
                    scenes.map((scene) => ({
                        ...scene,
                        totalHashes: parseInt(scene.totalHashes, 10) || 0,
                    }))
                )
            )
        );
    isExhausted(searchStr = '') {
        //example: if we have exhausted  query: 'de', then there is nothing more to fetch from query query: 'den'
        //first that will be checked in this is '', and if '' is exhausted, its no use fetching more from server
        for (let i = 0; i < searchStr.length; i++) {
            if (this.queryExhausted[searchStr.substring(0, i)]) {
                return true;
            }
        }
        return this.queryExhausted[searchStr];
    }
    fetchSeach = (searchStr) =>
        fetch(
            argUrl(API_SEARCH_ENDPOINT, {
                s: searchStr,
                ...(this.LastEvaluatedKey[searchStr]
                    ? this.LastEvaluatedKey[searchStr]
                    : null),
            })
        )
            .then((response) => response.json())
            .then((response) => {
                const scenes = response.Items.filter(
                    (s) => !this.scenes.find((ss) => ss.id === s.id)
                );
                if (scenes.length) {
                    this.setScenes(this.handleDirtyScenes(scenes));
                }
                if (!response.LastEvaluatedKey) {
                    this.queryExhausted[searchStr] = true;
                } else {
                    this.LastEvaluatedKey[searchStr] =
                        response.LastEvaluatedKey;
                }
                return 1;
            });

    fetchPage = () =>
        fetch(
            argUrl(
                API_GET_ENDPOINT,
                this.LastEvaluatedKey[''] ? this.LastEvaluatedKey[''] : null
            )
        )
            .then((response) => response.json())
            .then((response) => {
                const scenes = response.Items.filter(
                    (s) => !this.scenes.find((ss) => ss.id === s.id)
                );
                if (scenes.length) {
                    this.setScenes(this.handleDirtyScenes(scenes));
                }
                if (!response.LastEvaluatedKey) {
                    this.queryExhausted[''] = true;
                } else {
                    this.LastEvaluatedKey[''] = response.LastEvaluatedKey;
                }
                return 1;
            });

    create = (data) =>
        fetch(API_POST_ENDPOINT, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                const err = 'err' in response;
                if (!err) {
                    const newScene = this._createSceneFromResponseAndScene(
                        response,
                        data.scene,
                        data.comment
                    );
                    this.scenes.push(newScene);
                    this.setScenes(this.sortScenes(this.scenes));
                }
                return { err, response };
            });

    subscribeToChanges(subscriberCallback) {
        const id = Math.random();
        this.changeSubscribers[id] = subscriberCallback;
        return id;
    }

    unsubscribeToChanges(subscriberId) {
        delete this.changeSubscribers[subscriberId];
    }

    sortScenes = (scenes) =>
        scenes.sort((a, b) => b.totalHashes - a.totalHashes);

    _expandScenes = (scenes) =>
        scenes.map(
            (scene) =>
                scene.title
                    ? scene
                    : {
                          ...scene,
                          settings: {
                              key: scene.original + ';' + scene.name,
                              settings: JSON.parse(scene.settings),
                          },
                          title: scene.name || 'no title',
                          original: scene.original || 'no title',
                          imageUrl: getImgUrl(scene.id),
                      }
        );

    _broadcastNewScene() {
        for (const id of Object.keys(this.changeSubscribers)) {
            this.changeSubscribers[id](this.scenes);
        }
    }

    _createSceneFromResponseAndScene(response, scene, comment) {
        const id = response.id;
        scene = JSON.parse(scene);
        scene.settings = { key: scene.key, settings: scene.settings };
        scene.title = (scene.key.split(';')[1] || 'no title').toLowerCase();
        scene.totalHashes = 0;
        scene.imageUrl = getImgUrl(id);
        scene.comment = comment;
        return { ...scene, id, imageid: id };
    }
}

const sceneStore = new SceneStore();
export { sceneStore };
