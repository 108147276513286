import React from 'react';

const getEntities = (time, height, width) => {
	const halfHeight = height/8;
	const halfWidht = width/8;
	const entities = [];
	for(let i=0; i<Math.PI*2; i+=0.3){
		const val = (time + i);
		entities.push({
			top: Math.cos(val)*halfHeight+halfHeight*4+Math.tan(val/4)*halfHeight+Math.sin(val/8)*halfHeight,
			left: Math.sin(val)*halfWidht+halfWidht*4+Math.tan(val/4)*halfWidht+Math.cos(val/8)*halfWidht,
		})
	}
	return entities;
};
export default class LoadingWidget extends React.PureComponent{
	render = () => (
		<div style={{overflow: 'hidden', width: '100%', height: this.props.height, position: 'relative'}}>
			<h2 style={{position: 'absolute', top: this.props.height/4, left: '30%', fontWeight: 'bold'}}>Loading..</h2>
			<LoadingEntitiesContainer height={this.props.height} />
		</div>
	);
}

const rgb = (r,g,b) => `rgb(${r},${g},${b})`;

const LoadingEntities = ({val, height}) => getEntities(val, height, 100).map(({top, left}, index) => (
	<div 
		className="loadingEntity"
		style={{
			top,
			left: `${left}%`,
			backgroundColor: rgb(
				(Math.sin((top*left)/1000)+0.5)*255 - (Math.tan(top/10)+0.5)*255,
				(Math.cos((top*left)/1000)+0.5)*255 - (Math.tan(top/10)+0.5)*255,
				(Math.tan((top*left)/1000)+0.5)*255 - (Math.tan(top/10)+0.5)*255,
			),
		}}
		key={index}>
	</div>
));

class LoadingEntitiesContainer extends React.PureComponent {
	componentDidMount = () => this.intervalId  = window.requestAnimationFrame(this.loop);
	loop = () => {
		this.forceUpdate();
		this.intervalId  = window.requestAnimationFrame(this.loop);
	};
	componentWillUnmount = () => window.cancelAnimationFrame(this.intervalId);
	render = () => this.renderThing((new Date().getTime())/600);
	renderThing = (val) => (
		<div style={{overflow: 'hidden', width: '100%', height: this.props.height, position: 'relative'}}>
			<LoadingEntities val={val+Math.PI} height={this.props.height} />
			<LoadingEntities val={val+Math.PI*4} height={this.props.height} />
			<LoadingEntities val={val+Math.PI*8} height={this.props.height} />
			<LoadingEntities val={val+Math.PI*16} height={this.props.height} />
		</div>
	);
}