import React from 'react'
import App from './App';
import {MessageRenderer} from "./MessageRenderer";

const AppContainer = () => (
	<div>
		<MessageRenderer/>
		<App />
	</div>
);

export {
	AppContainer,
}
