import React from 'react'
import {ToastContainer, ToastMessageAnimated } from 'react-toastr';
import {MessageBroadcaster} from "../MessageBroadcaster";

const ToastMessageFactory = React.createFactory(ToastMessageAnimated);

class MessageRenderer extends React.PureComponent{
	componentDidMount(){
		this.exceptionSubscriberId = MessageBroadcaster.subscribeToExceptions(this.toastError);
		this.successSubscriberId = MessageBroadcaster.subscribeToSuccess(this.toastSuccess);
	}
	componentWillUnmount(){
		MessageBroadcaster.unsubscribeExceptions(this.exceptionSubscriberId);
		MessageBroadcaster.unsubscribeToSuccess(this.successSubscriberId);
	}
	toastError = (e) => this.container.error(e.msg, null, {timeOut: 6000, extendedTimeout: 1000});
	toastSuccess = (successHtml) => this.container.success(successHtml, null, {timeOut: 5000, extendedTimeout: 1000});
	render = () => (
		<ToastContainer
			ref={(input) => this.container = input}
			toastMessageFactory={ToastMessageFactory}
			className="toast-top-right"
			preventDuplicates={true}
		/>
	);
}

export {
	MessageRenderer,
}
