import React from 'react';
import { Col, Button } from 'react-bootstrap';
import copyImage from '../../assets/clippy.svg';
import { SceneThumbnail } from './SceneThumbnail';

const SceneCopyWidget = ({ text, id }) => (
    <div style={{ textAlign: 'center' }}>
        <input id={id} value={text} readOnly={true} style={{ width: '79%' }} />
        <button
            className="btn btn-default"
            data-clipboard-target={'#' + id}
            style={{ width: '19%' }}
        >
            <img
                src={copyImage}
                alt="Copy to clipboard"
                style={{ width: 15 }}
            />
        </button>
    </div>
);

class SceneItemWidget extends React.PureComponent {
    state = {
        zoom: false,
    };
    onToggleShowThumbnail = () => {
        const zoom = !this.state.zoom;
        if (zoom) {
            this.sceneThumbnailRef.setImageMaxSize();
        } else {
            this.sceneThumbnailRef.setImageDefaultSize();
        }
        this.setState({ zoom });
    };
    onClickedCol = (e) => {
        if (e.target.src && e.target.src.indexOf('clippy') === -1) {
            this.onToggleShowThumbnail();
        }
    };

    getColSizeForDevices = () => ({
        xs: 12,
        md: this.state.zoom ? 12 : 4,
        lg: this.state.zoom ? 12 : 3,
    });
    getTitleSplittedByFilter = () => ({
        matchedTitle: this.props.scene.title.substring(
            0,
            this.props.sceneFilter.length
        ),
        restOfTitle: this.props.scene.title.substring(
            this.props.sceneFilter.length
        ),
    });
    render() {
        const { scene, onToggleShowScene, showScene } = this.props;
        const { xs, md, lg } = this.getColSizeForDevices();
        const { matchedTitle, restOfTitle } = this.getTitleSplittedByFilter();
        return (
            <Col
                xs={xs}
                md={md}
                lg={lg}
                onClick={this.onClickedCol}
                className={scene.hidden ? '' : 'animated swing'}
                style={{ display: scene.hidden ? 'none' : 'inline-block' }}
            >
                <SceneThumbnail
                    retry
                    src={scene.imageUrl}
                    ref={(sceneThumbnailRef) =>
                        (this.sceneThumbnailRef = sceneThumbnailRef)
                    }
                >
                    <h4>
                        <span style={{ backgroundColor: '#FFFF00' }}>
                            {matchedTitle}
                        </span>
                        {restOfTitle}
                    </h4>
                    <small className="text-muted">
                        id: <span className="sceneid">{scene.id}</span>
                    </small>
                    <br />
                    <small className="text-muted">
                        original: {scene.original}
                    </small>
                    <br />
                    <Button onClick={onToggleShowScene} bsStyle="default">
                        {!showScene ? 'Get scene' : 'Hide scene'}
                    </Button>
                    {showScene && (
                        <SceneCopyWidget
                            text={JSON.stringify(scene.settings)}
                            id={'d' + scene.id}
                        />
                    )}
                    <br />
                    {scene.comment &&
                        scene.comment.length && (
                            <em>comment: {scene.comment}</em>
                        )}
                </SceneThumbnail>
            </Col>
        );
    }
}

class SceneItem extends React.PureComponent {
    state = {
        showScene: false,
        miner: undefined,
    };
    onToggleShowScene = () =>
        this.setState({ showScene: !this.state.showScene });
    render = () => (
        <SceneItemWidget
            scene={this.props.scene}
            onToggleShowScene={this.onToggleShowScene}
            showScene={this.state.showScene}
            sceneFilter={this.props.sceneFilter}
        />
    );
}

export { SceneItem };
