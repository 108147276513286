
const MessageBroadcaster = {

	exceptionSubscribers: {},
	sendException: function(msg){
		for(const subscriberId of Object.keys(this.exceptionSubscribers)){
			this.exceptionSubscribers[subscriberId](msg);
		}
	},
	subscribeToExceptions: function(subscriberCallback){
		const id = Math.random();
		this.exceptionSubscribers[id] = subscriberCallback;
		return id;
	},
	unsubscribeExceptions: function(id){
		delete this.exceptionSubscribers[id];
	},

	successSubscribers: {},
	sendSuccess: function(msg){
		for(const subscriberId of Object.keys(this.successSubscribers)){
			this.successSubscribers[subscriberId](msg);
		}
	},
	subscribeToSuccess: function(subscriberCallback){
		const id = Math.random();
		this.successSubscribers[id] = subscriberCallback;
		return id;
	},
	unsubscribeToSuccess: function(id){
		delete this.successSubscribers[id];
	}
};

export {
	MessageBroadcaster,
}