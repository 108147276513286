import {
    Button,
    Tooltip,
    OverlayTrigger,
    FormControl,
    HelpBlock,
    ControlLabel,
} from 'react-bootstrap';
import React from 'react';
import { sceneStore } from '../SceneStore';
import { MessageBroadcaster } from '../MessageBroadcaster';
import { ToBeReadException } from '../ToBeReadException';
import { SceneThumbnail } from './SceneThumbnail';

function FieldGroup({ label, help, ...props }) {
    return (
        <span>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </span>
    );
}

function isValidJson(json) {
    try {
        JSON.parse(json);
        return '';
    } catch (e) {
        return e.message + '\n';
    }
}
function isValidB64Json(value) {
    try {
        JSON.parse(atob(value));
        return '';
    } catch (e) {
        return e.message + '\n';
    }
}

const validateFormData = (formData) => {
    const errors = [];
    errors.push(formData.image.length ? '' : 'No image to upload');
    errors.push(
        formData.scene.length
            ? validateScene(formData.scene)
            : 'No scene to upload'
    );
    return errors;
};
const validateScene = (value) => {
    const jsonError = isValidJson(value);
    const b64Error = isValidB64Json(value);
    if (jsonError.length === 0 || b64Error.length === 0) {
        return '';
    }
    return `${jsonError} ${b64Error}`;
};
const parseJsonOrB64 = (value) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        try {
            return JSON.parse(atob(value));
        } catch (e) {
            throw new ToBeReadException([
                <strong key={1}>Scene is not in valid format</strong>,
                <em key={2}>
                    <br />
                    Scene needs to be json or b64 encoded json
                </em>,
            ]);
        }
    }
};

class UploadSceneWidget extends React.PureComponent {
    state = {
        formData: {
            image: [],
            scene: '',
            comment: '',
        },
        status: '',
        progress: 0.0,
        imgPreview: '',
        isUploading: false,
        shouldShowStatus: false,
    };
    constructor(props) {
        super(props);
        this.fileReader = new FileReader();
        this.fileReader.onload = (e) =>
            this.setState({ imgPreview: e.target.result });
    }
    onChangeImageInput = (e) => {
        this.setState({
            formData: { ...this.state.formData, image: e.target.files },
        });
        if (e.target.files.length) {
            this.fileReader.readAsDataURL(e.target.files[0]);
        }
    };
    onChangeSceneInput = (e) =>
        this.setState({
            formData: { ...this.state.formData, scene: e.target.value },
        });
    onChangeCommentInput = (e) =>
        this.setState({
            formData: { ...this.state.formData, comment: e.target.value },
        });

    isAllowedToUpload() {
        if (this.state.isUploading) {
            throw new ToBeReadException(
                <strong>You are already uploading</strong>
            );
        }
        if (!this.state.formData.image.length) {
            throw new ToBeReadException(
                (
                    <strong>
                        Scene needs to have an image showing the scene in action
                    </strong>
                )
            );
        }
    }
    onClickUpload = async () => {
        try {
            this.isAllowedToUpload();
            const scene = JSON.stringify(
                parseJsonOrB64(this.state.formData.scene)
            );
            const formData = { ...this.state.formData, scene };
            this.setState({
                shouldShowStatus: true,
                status: 'uploading',
                formData,
                isUploading: true,
            });
            const uploadResponse = await sceneStore.create({
                scene: formData.scene,
                comment: formData.comment,
                image: this.state.imgPreview,
            });
            if (uploadResponse.err) {
                MessageBroadcaster.sendException(
                    new ToBeReadException(<p>{uploadResponse.response.err}</p>)
                );
            } else {
                MessageBroadcaster.sendSuccess(
                    <p>Scene uploaded with id: {uploadResponse.response.id}</p>
                );
            }
            this.setState({
                progress: uploadResponse.err ? 0 : 1,
                status: uploadResponse.err ? 'Failed to upload' : 'Success',
                isUploading: false,
            });
            setTimeout(() => this.setState({ shouldShowStatus: false }), 1000);
        } catch (e) {
            this.setState({
                progress: 0,
                status: 'Failed to upload',
                isUploading: false,
            });
            MessageBroadcaster.sendException(e);
        }
    };
    render() {
        const formDataErrors = validateFormData(this.state.formData);
        const errorsRendered = formDataErrors
            .map(
                (error, index) =>
                    error.length ? <p key={index}>{error}</p> : null
            )
            .filter((elem) => elem !== null);
        const formDataInfoOverlay = (
            <Tooltip id="inputtooltip">
                {this.state.isUploading ? (
                    <p>Uploading..</p>
                ) : errorsRendered.length ? (
                    errorsRendered
                ) : (
                    <p>Click to Upload</p>
                )}
            </Tooltip>
        );
        return (
            <SceneThumbnail
                src={this.state.imgPreview}
                height={this.state.shouldShowStatus ? 470 : 350}
            >
                <form>
                    <FieldGroup
                        type="text"
                        label="Scene"
                        placeholder="Paste exported scene here"
                        onChange={this.onChangeSceneInput}
                    />
                    <FieldGroup
                        type="text"
                        label="Comment"
                        placeholder="Write a comment about your scene here (not required)"
                        onChange={this.onChangeCommentInput}
                    />
                    <FieldGroup
                        accept="image/png, image/jpeg"
                        type="file"
                        label="Image"
                        onChange={this.onChangeImageInput}
                    />

                    <div style={{ textAlign: 'center' }}>
                        <OverlayTrigger
                            placement="top"
                            overlay={formDataInfoOverlay}
                        >
                            <Button
                                bsStyle="default"
                                onClick={this.onClickUpload}
                            >
                                {this.state.isUploading
                                    ? 'Uploading..'
                                    : 'Upload'}
                            </Button>
                        </OverlayTrigger>
                    </div>
                </form>

                {this.state.shouldShowStatus && (
                    <div style={{ textAlign: 'center' }}>
                        <h4>status: {this.state.status}</h4>
                        <progress value={this.state.progress} max="1.0" />{' '}
                        <br />
                    </div>
                )}
            </SceneThumbnail>
        );
    }
}

export { UploadSceneWidget };
